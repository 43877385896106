import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import Head from "next/head";
import Script from "next/script";
import { useRouter } from "next/router";
import { PersistGate } from "redux-persist/integration/react";
import { wrapper, persistor, store } from "../src/redux/store";
import "../src/translations/i18n";
import "../styles/globals.css";
import "../styles/calendar.css";
import * as gtag from "../helpers/gtag";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Link from "next/link";
import { NextSeo } from "next-seo";

export default wrapper.withRedux(({ Component, pageProps }) => {
  const router = useRouter();
  const [isBlocked, setIsBlocked] = useState(false);

  const fetchIpAddress = async () => {
    const blockedCountryCodes = [
      "AT", // Austria
      "BE", // Belgium
      "BG", // Bulgaria
      "HR", // Croatia
      "CY", // Republic of Cyprus
      "CZ", // Czech Republic
      "DK", // Denmark
      "EE", // Estonia
      "FI", // Finland
      "FR", // France
      "DE", // Germany
      "GR", // Greece
      "HU", // Hungary
      "IE", // Ireland
      "IT", // Italy
      "LV", // Latvia
      "LT", // Lithuania
      "LU", // Luxembourg
      "MT", // Malta
      "NL", // Netherlands
      "PL", // Poland
      "PT", // Portugal
      "RO", // Romania
      "SK", // Slovakia
      "SI", // Slovenia
      "ES", // Spain
      "SE", // Sweden
    ];

    const responseIp = await fetch("https://api.seeip.org/jsonip");
    const dataIp = await responseIp.json();

    const responseCountry = await fetch(`https://api.country.is/${dataIp?.ip}`);
    const dataCountry = await responseCountry.json();
    if (
      blockedCountryCodes?.find((country) => country === dataCountry?.country)
    ) {
      setIsBlocked(true);
      // window.location.href = "https://soundclubmag.com";
    }
  };

  useEffect(() => {
    // fetchIpAddress();

    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  return (
    <Provider store={store}>
      <ToastProvider
        placement="bottom-center"
        newestOnTop={true}
        style={{ flex: 1, height: "100%" }}
        autoDismiss={true}
        autoDismissTimeout={2000}
        components={{
          Toast: ({ children }) => (
            <div
              style={{
                background: "rgba(0,0,0,0.8)",
                color: "white",
                padding: "14px 18px",
                borderRadius: 12,
                marginBottom: 12,
              }}>
              {children}
            </div>
          ),
        }}>
        <Head>
          <meta content="initial-scale=1.0;" name="viewport" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="robots"
            content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          />

          <link rel="icon" href="/favicon-supervybe.png" sizes="32x32" />
          <link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css" />
          {/* <script
            type="text/javascript"
            src="https://app.termly.io/embed.min.js"
            async
            data-website-uuid="081d4dd0-9fb1-4418-8819-c3eec7fefd28"></script> */}
        </Head>

        <Script
          strategy="lazyOnload"
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-JFWYYYJKKT"
        />
        <Script
          id="google-analytics"
          strategy="lazyOnload"
          async
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-JFWYYYJKKT', {
            page_path: window.location.pathname,
          });
          `,
          }}
        />
        {/* <Script
          async
          strategy="lazyOnload"
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2382089054646980"
          crossOrigin="anonymous"
        /> */}
        {/* <div className="bg-black text-white uppercase h-screen w-screen font-regular text-4xl flex justify-center items-center">
          WE WILL BE BACK SOON
        </div> */}
        {/* <div className="bg-black h-screen w-screen flex flex-col justify-center items-center">
          <div className="sm:text-3xl text-lg font-bold text-white text-center sm:mb-14 mb-6">
            SOUNDCLUB.COM
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="sm:text-[26px] text-sm font-bold text-center text-white80 mb-8">
              Soundclub has now moved to{" "}
              <Link href="https://www.festyful.com">
                <a className="text-pink underline">festyful.com</a>
              </Link>{" "}
              (festivals) and{" "}
              <Link href="https://www.vybeful.com">
                <a className="text-pink underline">vybeful.com</a>
              </Link>{" "}
              (venues) - JOIN US NOW
            </div>
            <div className="sm:text-lg text-sm font-regular text-center text-white80 mb-8">
              soundclub.com is now available for sale. The price includes a
              package of all the related relevant premium domain names to assure
              maximum brand name protection.
            </div>
            <div className="sm:text-lg text-sm font-regular text-center text-white80 mb-8">
              For enquiries about the sale, please email us at{" "}
              <Link href="mailto:maxbh93@live.it">
                <a className="text-white underline">maxbh93@live.it</a>
              </Link>
            </div>
            <div className="sm:text-lg text-sm font-regular text-center text-white mb-8">
              <ul>
                <li>soundclub.com</li>
                <li>sound-club.com</li>
                <li>soundclubs.com</li>
                <li>thesoundclub.com</li>
                <li>soundclub.net</li>
                <li>sound.club</li>
                <li>soundclub.co</li>
                <li>soundclub.io</li>
                <li>soundclub.xyz</li>
                <li>soundclub.app</li>
                <li>soundclub.fm</li>
                <li>soundclub.ai</li>
                <li>soundclub.us</li>
                <li>soundclub.co.uk</li>
              </ul>
            </div>
          </div>
        </div> */}
        {/* <NextSeo
          title="Rooftop Club"
          canonical={`https://www.rooftopclub.co`}
          openGraph={{
            type: "website",
            locale: "en_EN",
            url: "https://www.rooftopclub.co",
            title: "Rooftop Club",
            images: [
              {
                url: "https://www.rooftopclub.co/images/og-rooftopclub.png",
                width: 1200,
                height: 630,
                alt: "Rooftop Club",
                type: "image/png",
              },
            ],
            siteName: "Rooftop Club",
          }}
        />

        <div className="bg-white w-screen flex flex-col justify-center items-center">
          <div className="absolute bg-white top-0 left-0 w-full h-full -z-10" />
          <div className="text-base font-regular text-black p-6 text-center">
            <div className="flex items-center justify-center my-12">
              <img src="/images/logo-rooftopclub.svg" alt="logo" width="300" />
            </div>
            <h1 className="text-2xl font-bold">
              <strong>
                Rooftop Club is the #1 platform for rooftop venues in the world
                with +3,000 rooftops in +300 cities around the world.&nbsp;
              </strong>
            </h1>
            <p className="text-xl font-bold mt-4">
              <strong>WE ARE LAUNCHING IN AUGUST 2023. JOIN US.</strong>
            </p>
            <h2 className="font-bold text-lg mt-4">Rooftop Bar</h2>
            <p>
              A rooftop bar is a great way to take in the sights and sounds of
              the city. Enjoy a drink or two while experiencing spectacular
              views of iconic landmarks, beautiful sunsets, and city lights
              twinkling in the night sky. Whether you&apos;re looking to kick
              back with friends or have a romantic evening with your significant
              other, rooftop bars provide an atmosphere unlike any other. With
              innovative cocktail menus, delicious snacks, and unbeatable views,
              there&apos;s something for everyone at a rooftop bar. Enjoy
              classic favorites like martinis, margaritas, and mojitos while
              savoring fresh seafood dishes such as local oysters on the half
              shell or grilled octopus with saut&eacute;ed vegetables. Choose
              from an array of cozy seating options like couches and daybeds to
              soak up the sun or cozy up under blankets for a starry sky view.
              Spend your evening listening to live music and watching the
              cityscape come alive below.
            </p>
            <h2 className="font-bold text-lg mt-4">Rooftop Pool</h2>
            <p>
              Adding a rooftop pool to the mix can elevate your experience even
              more. Enjoy taking a dip in the cool, refreshing waters while
              enjoying the stunning views of the city below. With cozy lounge
              chairs and umbrellas, you can take in the scenery while relaxing
              in comfort. For an unforgettable evening, why not try taking a
              swim followed by drinks under the stars? You&apos;ll be sure to
              make lasting memories with friends or that special someone. Many
              rooftop bars also offer food and drink options such as light bites
              like salads and sandwiches, or even full meals like steaks,
              pastas, and seafood. With specialty cocktails such as margaritas
              and mojitos mixed with fresh fruit juices, you can sip on
              something delicious while soaking up stunning sunsets or starry
              night skies. Whether you&rsquo;re looking for luxury or just want
              to have some fun in the sun, rooftop pools are sure to provide an
              unforgettable experience!
            </p>
            <h2 className="font-bold text-lg mt-4">Rooftop Restaurant</h2>
            <p>
              A rooftop restaurant is an unforgettable dining experience that
              offers much more than just great food. Perched atop a building,
              diners will be able to enjoy breathtaking views of the city
              skyline and take in fresh air while savoring delicious meals.
              Popular dishes include tender steaks, succulent seafood entrees,
              and creative gourmet salads. Many rooftop restaurants also feature
              an extensive wine list with refined varietals from around the
              world. From comfort foods like mouth-watering mac and cheese to
              elevated classics such as truffle ravioli, you can find something
              for everyone at a rooftop restaurant. When the sun sets, the mood
              changes and so do the offerings - look out for specialty cocktails
              paired with seasonal tapas dishes to share with friends or family
              while marveling at the night sky. Whether it&apos;s date night or
              dinner with a group of friends, a rooftop restaurant offers an
              unparalleled dining experience unlike any other.
            </p>
            <h2 className="font-bold text-lg mt-4">Rooftop Cinema</h2>
            <p>
              A rooftop cinema experience can be a truly unique and memorable
              way to enjoy the evening. Imagine settling in with friends and
              loved ones under the stars while taking in your favorite movie or
              a classic classic flick. Whether it&apos;s an indie drama, a
              blockbuster action movie, or a beloved romantic comedy,
              you&apos;ll be able to watch it all from the comfort of your own
              rooftop terrace. Enjoy delicious snacks and drinks while you relax
              on cozy couches or outdoor seating while taking in breathtaking
              views of the city skyline. For an even more luxurious experience,
              some venues offer special packages that include gourmet meals
              paired with premium wines or craft beers.
            </p>
            <p>
              Rooftop cinemas are becoming increasingly popular due to their
              unique offerings and unbeatable atmosphere. Many feature modern
              state-of-the-art equipment for sound and picture quality that
              surpasses that of traditional theaters. They also often feature
              creative themes - like silent film screenings accompanied by live
              music performances - to enhance guests&apos; viewing experiences.
              Some even come with luxurious perks like private cabanas for VIP
              viewing areas as well as outdoor fireplaces to keep guests warm
              during chilly nights. No matter what type of movie you choose to
              watch, a rooftop cinema will provide you with an unforgettable
              night under the stars!
            </p>
          </div>
        </div> */}
        {/* <NextSeo
          title="Krigav"
          canonical={`https://www.krigav.com`}
          openGraph={{
            type: "website",
            locale: "en_EN",
            url: "https://www.krigav.com",
            title: "Krigav",
            images: [
              {
                url: "https://www.krigav.com/images/og-krigav2.png",
                width: 1200,
                height: 630,
                alt: "Krigav",
                type: "image/png",
              },
            ],
            siteName: "Krigav",
          }}
        />

        <div className="bg-black h-screen w-screen flex flex-col items-center">
          <div className="absolute bg-white top-0 left-0 w-full h-full -z-10" />
          <div className="flex flex-col items-center justify-center flex-1 text-base font-regular text-white p-6 text-center">
            <div className="flex items-center justify-center mb-12">
              <img src="/images/logo-krigav3.png" alt="logo" width="343" />
            </div>
            <h1 className="hidden">Krigav</h1>
            <p className="sm:text-[26px] text-sm font-bold text-center text-white80">
              DIGITAL MEDIA INVESTING, MANAGEMENT & ADVISORY
            </p>
          </div>
        </div> */}
        <NextSeo
          title="Supervybe"
          canonical={`https://www.supervybe.com`}
          openGraph={{
            type: "website",
            locale: "en_EN",
            url: "https://www.supervybe.com",
            title: "Supervybe",
            images: [
              {
                url: "https://www.supervybe.com/images/og-supervybe.png",
                width: 1200,
                height: 630,
                alt: "Supervybe",
                type: "image/png",
              },
            ],
            siteName: "Supervybe",
          }}
        />

        <div className="bg-black h-screen w-screen flex flex-col items-center">
          <div className="absolute bg-white top-0 left-0 w-full h-full -z-10" />
          <div className="flex flex-col items-center justify-center flex-1 text-base font-regular text-white p-6 text-center">
            <div className="flex items-center justify-center mb-12">
              <img src="/images/logo-supervybe.svg" alt="logo" width="343" />
            </div>
            <h1 className="hidden">Supervybe</h1>
            {/* <p className="sm:text-[26px] text-sm font-bold text-center text-white80">
              DIGITAL MEDIA INVESTING, MANAGEMENT & ADVISORY
            </p> */}
          </div>
        </div>
        {/* <NextSeo
          title="WRSocial"
          canonical={`https://www.wrsocial.com`}
          openGraph={{
            type: "website",
            locale: "en_EN",
            url: "https://www.wrsocial.com",
            title: "WRSocial",
            images: [
              {
                url: "https://www.wrsocial.com/images/og-wrsocial.png",
                width: 1200,
                height: 630,
                alt: "WRSocial",
                type: "image/png",
              },
            ],
            siteName: "WRSocial",
          }}
        />

        <div className="bg-black h-screen w-screen flex flex-col items-center">
          <div className="absolute bg-white top-0 left-0 w-full h-full -z-10" />
          <div className="text-base font-regular text-white p-6 text-center">
            <div className="flex items-center justify-center my-12">
              <img src="/images/logo-wrsocial.svg" alt="logo" width="300" />
            </div>
            <h1 className="text-2xl font-bold">
              <strong>
                WRSocial is a social platform for IRL social experiences. It
                covers +1,500 venues in +20 cities around the world.
              </strong>
            </h1>
            <p className="text-xl font-bold mt-4">
              <strong>COMING SOON.</strong>
            </p>
            <h2 className="font-bold text-lg mt-4">Experiences</h2>
            <p>
              Axe throwing
              <br />
              Batting
              <br />
              Beer Pong
              <br />
              Bowling
              <br />
              Darts
              <br />
              Escape Room
              <br />
              Mini Golf
              <br />
              Ping Pong
              <br />
              Shuffleboard
              <br />
              VR
              <br />
              Bingo
              <br />
              Arcade
              <br />
              Karaoke
              <br />
              Pool
              <br />
              Laser Tag
              <br />
              Shooting
              <br />
              Video Games
              <br />
              And much more
              <br />
            </p>
          </div>
        </div> */}
        {/* <NextSeo
          title="Tastyful"
          canonical={`https://www.tastyful.co`}
          openGraph={{
            type: "website",
            locale: "en_EN",
            url: "https://www.tastyful.co",
            title: "Tastyful",
            images: [
              {
                url: "https://www.tastyful.co/images/og-tastyful.png",
                width: 1200,
                height: 630,
                alt: "Tastyful",
                type: "image/png",
              },
            ],
            siteName: "Tastyful",
          }}
        />

        <div className="bg-black h-screen w-screen flex flex-col items-center">
          <div className="absolute bg-white top-0 left-0 w-full h-full -z-10" />
          <div className="text-base font-regular text-white p-6 text-center">
            <div className="flex items-center justify-center my-12">
              <img src="/images/logo-tastyful.svg" alt="logo" width="300" />
            </div>
            <h1 className="text-2xl font-bold">
              <strong>
                Tastyful is a social platform for selected restaurants. It
                covers +1,000 restaurants in +20 cities around the world.
              </strong>
            </h1>
            <p className="text-xl font-bold mt-4">
              <strong>COMING SOON.</strong>
            </p>
            <h2 className="font-bold text-lg mt-4">Restaurants</h2>
            <p>
              A restaurant is an establishment that provides customers with food
              and drinks, usually in exchange for money. Restaurants range from
              small family-owned establishments to large chain restaurants. The
              menu may include a variety of dishes, including appetizers, main
              courses, desserts, and beverages. A restaurant typically has a
              full-service kitchen, bar area, dining room, and staff of servers
              and cooks. Some restaurants also have outdoor seating areas or
              patios for guests to enjoy during warmer months. Additionally,
              many restaurants offer takeout options so customers can enjoy the
              same delicious food in the comfort of their home. Restaurants come
              in all shapes and sizes and serve a variety of cuisines from
              traditional to ethnic to fusion. Many restaurants are designed to
              provide an enjoyable atmosphere that encourages socialization as
              much as eating—lounges often feature live music while dining rooms
              may be more formal with higher quality furnishings and decor.
            </p>
          </div>
        </div> */}
        {/* <NextSeo
          title="Beachful"
          canonical={`https://www.beachful.co`}
          openGraph={{
            type: "website",
            locale: "en_EN",
            url: "https://www.beachful.co",
            title: "Beachful",
            images: [
              {
                url: "https://www.beachful.co/images/og-beachful.png",
                width: 1200,
                height: 630,
                alt: "Beachful",
                type: "image/png",
              },
            ],
            siteName: "Beachful",
          }}
        />

        <div className="bg-black h-screen w-screen flex flex-col items-center">
          <div className="absolute bg-white top-0 left-0 w-full h-full -z-10" />
          <div className="text-base font-regular text-white p-6 text-center">
            <div className="flex items-center justify-center my-12">
              <img src="/images/logo-beachful.svg" alt="logo" width="300" />
            </div>
            <h1 className="text-2xl font-bold">
              <strong>
                Beachful is a social platform for selected beach clubs. It
                covers +200 beach clubs in +20 cities around the world.
              </strong>
            </h1>
            <p className="text-xl font-bold mt-4">
              <strong>COMING SOON.</strong>
            </p>
            <h2 className="font-bold text-lg mt-4">Beach Club</h2>
            <p>
              A beach club is an ideal place to relax and enjoy the beauty of
              the ocean. It&apos;s a perfect escape from the hustle and bustle
              of daily life, as it offers many amenities for recreation and
              leisure. Beach clubs offer a variety of activities such as
              swimming, sunbathing, boating, kayaking, paddle boarding, fishing,
              and much more. You can also find restaurants, bars, and spas
              inside the beach club to make your stay even more enjoyable. With
              modern facilities and amenities to keep you safe and comfortable
              while you&apos;re having fun in the sun - beach clubs provide an
              idyllic getaway for friends or family.
            </p>
          </div>
        </div> */}
        {/* 
        <NextSeo
          title="Spa Club"
          canonical={`https://www.spaclub.co`}
          openGraph={{
            type: "website",
            locale: "en_EN",
            url: "https://www.spaclub.co",
            title: "Spa Club",
            images: [
              {
                url: "https://www.spaclub.co/images/og-spaclub.png",
                width: 1200,
                height: 630,
                alt: "Spa Club",
                type: "image/png",
              },
            ],
            siteName: "Spa Club",
          }}
        />
        
        <div className="bg-black h-screen w-screen flex flex-col items-center">
          <div className="absolute bg-white top-0 left-0 w-full h-full -z-10" />
          <div className="text-base font-regular text-white p-6 text-center">
            <div className="flex items-center justify-center my-12">
              <img src="/images/logo-spaclub.svg" alt="logo" width="300" />
            </div>
            <h1 className="text-2xl font-bold">
              <strong>
                Spa Club is a social platform for selected spa. It covers +1,000
                spa in +50 cities around the world.
              </strong>
            </h1>
            <p className="text-xl font-bold mt-4">
              <strong>COMING SOON.</strong>
            </p>
            <h2 className="font-bold text-lg mt-4">Spa</h2>
            <p>
              A spa is a type of facility that helps people relax and rejuvenate
              their body and mind. It typically includes a variety of treatments
              such as massage, body wraps, facials, saunas, steamrooms,
              manicures, pedicures, and other beauty services. Spas are often
              themed and offer specialized services for certain conditions like
              sports injuries or relaxation therapies. They may also offer
              classes in yoga or meditation to promote overall well-being. Many
              spas have multiple locations with amenities such as hot tubs,
              pools, outdoor cabanas with fireplaces, and sun decks to allow
              guests to enjoy the natural environment. Some spas also include
              private rooms for couples&apos; massages and luxurious bath
              amenities like aromatherapy oils and soothing music to enhance the
              experience.
            </p>
          </div>
        </div> */}
        {/* <div className="bg-black h-screen w-screen flex flex-col justify-center items-center">
          <div className="sm:text-3xl text-lg font-bold text-blue text-center sm:mb-[45px] mb-6">
            SOUNDCLUB.COM
          </div>
          <div className="flex flex-col justify-center w-full max-w-[650px]">
            <div className="sm:text-2xl text-base font-bold text-center text-white mb-[63px]">
              Soundclub has moved to
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="w-full max-w-[250px] flex flex-col justify-center items-center mx-4">
                <Link href="https://www.festyful.com">
                  <a
                    target="_blank"
                    className="text-pink font-bold sm:text-2xl text-lg bg-white h-[50px] flex justify-center items-center w-full rounded-full">
                    festyful.com
                  </a>
                </Link>
                <div className="text-grayText2 font-bold sm:text-xl text-base mt-[30px]">
                  FOR FESTIVALS
                </div>
              </div>
              <div className="w-full max-w-[250px] flex flex-col justify-center items-center mx-4">
                <Link href="https://www.vybeful.com">
                  <a
                    target="_blank"
                    className="text-pink font-bold sm:text-2xl text-lg bg-white h-[50px] flex justify-center items-center w-full rounded-full">
                    vybeful.com
                  </a>
                </Link>
                <div className="text-grayText2 font-bold sm:text-xl text-base mt-[30px]">
                  FOR VENUES
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* {isBlocked ? (
          <div>Blocked your country</div>
        ) : (
          <Component {...pageProps} />
        )} */}
      </ToastProvider>
    </Provider>
  );
});
